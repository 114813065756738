import * as React from 'react';
import remark from 'remark';
import remarkHTML from 'remark-html';

interface HTMLParserProps {
    markdown?: string;
    yaml?: Node;
}

const toHTML = (value) =>
    remark().use(remarkHTML).processSync(value).toString();

const HTMLParser = ({ markdown, yaml }: HTMLParserProps) => {
    const createMarkup = () => {
        if (markdown) {
            return { __html: markdown };
        }

        return { __html: toHTML(yaml) };
    };

    return (
        <div className="description" dangerouslySetInnerHTML={createMarkup()} />
    );
};

export default HTMLParser;
